// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-author-bios-js": () => import("./../../../src/pages/author-bios.js" /* webpackChunkName: "component---src-pages-author-bios-js" */),
  "component---src-pages-collection-js": () => import("./../../../src/pages/collection.js" /* webpackChunkName: "component---src-pages-collection-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-p-js": () => import("./../../../src/pages/p.js" /* webpackChunkName: "component---src-pages-p-js" */),
  "component---src-templates-post-post-jsx": () => import("./../../../src/templates/Post/Post.jsx" /* webpackChunkName: "component---src-templates-post-post-jsx" */)
}

